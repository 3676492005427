import React, {useState} from "react";
import styled from "styled-components";
import { Layout } from "../components/Layout";
import {StaticImage} from "gatsby-plugin-image";

import {T} from "../styles/Theme"

import {ReactComponent as IngloboLogo} from "../static/seminarioweb/inglobo.svg"
import {ReactComponent as ProChile} from "../static/seminarioweb/prochile.svg"

const Seminario = () => {
    // const sgMail = require('@sendgrid/mail')
    const [isFormSend, setIsFormSend] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");
    const [country, setCountry] = useState("");

    const handleClick = async(e) => {
        e.preventDefault()
        const body = {
            name,
            email,
            phone,
            company,
            country
        }
        await fetch('/api/mail-seminario', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        })
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': "registro"});
        setIsFormSend(true)
    }

    return (
        <Layout>
            <SeminarioStyled>
                <div className="seminario--header">
                    <StaticImage
                        className="seminario--header_image"
                        src="../static/seminarioweb/Imagen232.png"
                        alt={"seminario web"}
                    />
                    <h2 className="seminario--header_title"><span>SEMINARIO</span> WEB</h2>
                </div>

                {
                    !isFormSend ?
                        <>
                            <h4 className="seminario--h4">
                                <i>
                                    "Estrategias para cumplir con el mercado exportador de fruta fresca"
                                </i>
                            </h4>

                            <StaticImage
                                className="seminario--info_image"
                                src="../static/seminarioweb/Grupo.png"
                                alt={"seminario web"}
                            />

                            <p>
                                Inscríbete gratis - Cupos Limitados <br/>Reserva tu lugar en el siguiente formulario:
                            </p>

                            <form onSubmit={e => handleClick(e)}>
                                <InputStyled name="name" type="text" placeholder="Nombre" onChange={e => setName(e.target.value)} required/>
                                <InputStyled name="company" type="text" placeholder="Empresa" onChange={e => setCompany(e.target.value)} required/>
                                <InputStyled name="country" type="text" placeholder="País" onChange={e => setCountry(e.target.value)} required/>
                                <InputStyled name="email" type="text" placeholder="E-mail" onChange={e => setEmail(e.target.value)} required/>
                                <InputStyled name="phone" type="text" placeholder="Whatsapp" onChange={e => setPhone(e.target.value)} required/>

                                <SeminarioButton type={"submit"}>
                                    Enviar
                                </SeminarioButton>
                            </form>
                        </>
                        :
                        <div className="gracias-container">

                            <h4 className="seminario--gracias">
                                ¡Gracias por inscribirte en el Seminario Web!
                            </h4>
                            <h4 className="seminario--h4">
                                <i>
                                    "Estrategias para cumplir con el mercado exportador de fruta fresca"
                                </i>
                            </h4>

                            <p className="seminario--teinvitamos">
                                Te invitamos a unirte al seminario web Zoom desde tu país
                                <br/>
                                Cuándo: martes 14 jun 2022, 11:30 AM. Santiago, Chile
                                <br/>
                                El día del evento haga clic en el siguiente enlace para unirse:
                            </p>

                            <a className="seminario--zoom-link" href="https://us06web.zoom.us/j/87925685056">
                                https://us06web.zoom.us/j/87925685056
                            </a>
                        </div>

                }

                <div className="organizan">
                    <p>ORGANIZAN</p>
                    <IngloboLogo/>
                    <ProChile/>
                </div>
            </SeminarioStyled>
        </Layout>
    );
};

export default Seminario;

const SeminarioStyled = styled.div`
  box-sizing: border-box;
  margin: 100px auto 0 auto;
  min-height: 100vh;
  font-size: 16px;
  text-align: center;
  //max-width: 1050px;
  
  .seminario--header {
    position: relative;
    //max-width: 990px;
    width: 100%;
    margin: auto;
  }
  
  .seminario--header_image {
    margin: auto;
    min-height: 200px;
  }
  
  .seminario--header_title {
    font-size: 25px;
    color: ${T.gray};
    font-weight: 500;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-70%);
    
    & > span {
      font-weight: 900;
    }
  }
  
  @media (min-width: 580px) {
    .seminario--header_title {
      //left: 40px;
      //bottom: 35%;
      font-size: 45px;
    }
  }
  
  .seminario--h4 {
    font-size: 25px;
    text-align: center;
    max-width: 500px;
    margin: 30px auto;
    padding: 0 30px 30px 30px;
    margin-bottom: 0;
    line-height: 1;
    font-weight: 500;
  }
  
  .seminario--gracias {
    font-size: 25px;
    text-align: center;
    max-width: 700px;
    font-weight: 400;
    margin: 30px auto;
  }
  
  .gracias-container {
    margin-bottom: 60px;
    padding: 15px;
  }
  
  .seminario--teinvitamos {
    max-width: 600px;
    margin: 10px auto 60px auto;
    text-align: center;
  }
  
  .seminario--zoom-link {
    color: #2680EB;
    text-decoration: none;
    text-underline: none;
    
    &:visited, &:active {
      color: #2680EB;
    }
  }
  
  .seminario--info_image {
    max-width: 300px;
    margin-bottom: 20px;
  }
  
  & > ul {
    padding: 0;
    list-style: none;
    
    text-align: center;
  }
  
  .seminario--subtext {
    font-size: 25px;
    margin-top: 40px;
  }
  
  & > form {
    margin: 60px auto 80px auto;
    max-width: 550px;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
  
  .organizan {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 60px;
    
    & > p {
      margin-right: 10px;
      font-size: 25px;
      font-weight: 500;
      letter-spacing: 14px;
    }
    
    & > svg {
      margin: 0 15px;
    }
  }
`;

const InputStyled = styled.input`
  //max-width: 550px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 19px;
  height: 60px;
  padding: 0 20px;
  margin: 6px 0;
  box-sizing: border-box;
  
  font-size: 20px;
  font-weight: bold;
  color: black;
  
  &::placeholder {
    color: #C5C5C5;
  }
  
  box-shadow: 1px 4px 19px -2px rgba(0,0,0,0.15);
  -webkit-box-shadow: 1px 4px 19px -2px rgba(0,0,0,0.15);
  -moz-box-shadow: 1px 4px 19px -2px rgba(0,0,0,0.15);

`;

const SeminarioButton = styled.button`
  font-size: 24px;
  font-weight: 900;

  margin-top: 4px;
  max-width: 590px;
  width: 100%;
  border: none;
  border-radius: 19px;
  height: 60px;
  background-color: #FAAE00;
  color: white;
  box-sizing: border-box;
  
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  
  box-shadow: 1px 4px 19px -2px rgba(0,0,0,0.35);
  -webkit-box-shadow: 1px 4px 19px -2px rgba(0,0,0,0.35);
  -moz-box-shadow: 1px 4px 19px -2px rgba(0,0,0,0.35);
`;